<template>
<div class="text-center relative h-screen overflow-auto">
    <MobileHeader :noPin="!pinExist"/>
    <div v-if="!found" class="mx-8">
        <qrcode-stream @decode="onDecode" @init="onInit" />
        <div class="">
            <div v-if="error">
                <SystemMessage :text="error" type='error' />
            </div>
            <Input placeholder="entity ID" :value="this.result" id="qrcode_entity"/>
            <Button :text="$t('qrScan.openBtn')" buttonType="primary" v-on:click="elementCheck('')"/>
        </div>
    </div>
    <div v-else class="mt-8">
        <div class="mx-auto w-44 rounded-t-full rounded-b h-52 overflow-hidden ">
            <img :src="entity_img" class="object-cover h-full w-full">
        </div>
        <p class="dark:text-redDamask text-redDamask font-body text-bodyL">{{ $t('qrScan.foundText') }}{{ trans_id }}</p>
        <h5 class="font-heading text-h5 text-sepiaBlack dark:text-white" v-html="entity_name"></h5>
        <div class="mt-11 w-48 mx-auto space-y-2">
            <Button :text="$t('qrScan.openBtn')" buttonType="primary" :href="this.entity_link" id="openButton" />
            <Button :text="$t('qrScan.backBtn')" buttonType="secondary" v-on:click="backToRead"/>
        </div>
    </div>
    <MobileNavigation :pinExist="pinExist" class="bottom-0"/>
</div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';

import MobileNavigation from "@/components/mobile/Navigation.vue";
import Button from '@/components/Button.vue';
import SystemMessage from '@/components/SystemMessage.vue';
import Input from '@/components/Input.vue';
import MobileHeader from "@/components/mobile/Header.vue";

export default {
    name: 'QRCodeRead',
    components: {
        MobileNavigation,
        QrcodeStream,
        Button,
        Input,
        SystemMessage,
        MobileHeader
    },
    data() {
        return {
            trans_id: '',
            entity_id: '',
            entity_name: '',
            entity_img: '',
            entity_link: '#',
            found: false,
            result: '',
            error: '',
            language: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            baseURL: process.env.VUE_APP_AXIOS_URL,
            name: window.localStorage.name,
            pinExist: false,
        }
    },
    mounted() {
        this.$i18n.locale = this.language;
        this.pinCheck();
    },
    methods: {
        onDecode (result) {
            // console.log(result.split('=')[1]);
            this.result = result.split('=')[1];
            this.elementCheck(this.result);
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                this.error = "ERROR: you need to grant camera access permission"
                } else if (error.name === 'NotFoundError') {
                this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                this.error = "ERROR: Stream API is not supported in this browser"
                } else if (error.name === 'InsecureContextError') {
                this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                this.error = `ERROR: Camera error (${error.name})`;
                }
            }
        },
        elementCheck(id) {
            this.error = '';
            this.entity_name = '';
            this.entity_img = '';
            this.trans_id = '';
            this.entity_link = '#';
            this.found = false;
            this.entity_id = id != '' ? id : document.querySelector('#qrcode_entity').value;

            const variabels = {language: this.language};
            this.axios.get(this.baseURL + `/wp-json/wp/v2/mobile/` + this.entity_id + `/check`, {params: variabels}, {})
            .then(response => {
                const response_data = response.data;
                this.found = response_data.found
                if (response_data.found) {
                    this.trans_id = response_data.trans_id;
                    this.entity_img = response_data.img;
                    this.entity_name = response_data.title;
                    this.entity_link = '/mobile/' + response_data.trans_id;
                } else {
                    this.error = this.$t('qrScan.badEntityError');
                }
            }).catch( () => {
                this.error = this.$t('qrScan.badEntityError');
            });
        },
        backToRead() {
            this.error = '';
            this.entity_name = '';
            this.entity_img = '';
            this.trans_id = '';
            this.entity_link = '#';
            this.found = false;
        },
        pinCheck() {
            try{
                const result = this.$store.dispatch('pinCheck');
                result.then(res => {
                    if (res && res != '') {
                        this.pinExist = true;
                    } else {
                        this.pinExist = false;
                    }
                }).catch(() => {
                    this.pinExist = false;
                })
            }catch(e){
                this.pinExist = false;
            }
        },
    },
    computed: {
        src() {
        if (window.localStorage.theme == 'light') {
            return require("../../assets/Logo-dark.png");
        } else {
            return require("../../assets/Logo-light.png")
        }
        }
    }
}
</script>

<style>
#openButton > a {
    width: 100%;
}

</style>
