<template>
   <div class="max-w-screen-mobile mx-auto h-screen py-2">
     <QRCodeRead />
   </div>
</template>

<script>
import QRCodeRead from "@/components/mobile/QRCodeRead.vue"

export default {
  name: 'MobileQRCodeReader',
  components: {
    QRCodeRead
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  }
}
</script>
