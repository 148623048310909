<template>
  <div class="max-w-screen-mobile mx-auto h-screen  py-2 text-center realtive">
    <Header :language="lang" :noPin="!pinExist"/>
    <div class="text-center mx-6 h-3/5 flex flex-col justify-center">
      <h4 class="font-heading text-h4 text-sepiaBlack dark:text-white">{{ $t('language.selectTitle') }}</h4>
      <a href="/mobile/menu">
        <Language flag mobile :language="lang" @langchanged="langchanged" class="my-4"/>
      </a>
      <p class="font-body text-bodyL text-sepiaBlack dark:text-white px-6">{{ $t('language.text') }}</p>
    </div>
    <!-- <Button href="/mobile/menu" :text="$t('language.saveBtn')" buttonType="primary" fullWidth class="absolute bottom-8 inset-x-12"/> -->
  </div>
</template>

<script>

import Language from '@/components/Language.vue';
// import Button from '@/components/Button.vue';
import Header from "@/components/mobile/Header.vue";

export default {
	name: 'LanguageMobile',
	components: {
		Language,
		// Button,
		Header
	},
	data() {
		return {
			lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            pinExist: false,
		}
	},
	mounted() {
        this.pinCheck();
    },
	methods: {
		getLogo: function() {
		const element = document.getElementById("app");
		if (element.classList.contains('darkMode')) {
			return require("../assets/logo_dark.png");
		} else {
			return require("../assets/logo_light.png");
		}
		},
		langchanged(value) {
			this.lang = value;
		},
		pinCheck() {
            try{
                const result = this.$store.dispatch('pinCheck');
                result.then(res => {
                    if (res && res != '') {
                        this.pinExist = true;
                    } else {
                        this.pinExist = false;
                    }
                }).catch(() => {
                    this.pinExist = false;
                })
            }catch(e){
                this.pinExist = false;
            }
        },
  	}
}
</script>
